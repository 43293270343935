import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Input,
} from "reactstrap"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import GetObjectsByValues from "_mock/Static_data"
import StockRegisterTable from "./StockRegisterTable"
import DeleteModel from "common/deleteModel/deleteModel"

let changeQtys = []
function StockeRegisterReport() {
    document.title = `Stock register | ${localStorage.getItem("ShopName")}`
    const { postApi } = useApi()
    let { pathname } = useLocation()
    GetObjectsByValues(1, 2, 3, 4, 5)

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [displayStart, setDisplayStart] = useState(1)
    const [searchText, setSearchText] = useState("")
    const [enableProductStock, setEnableProductStock] = useState(false)
    const [stoctTable, setStockTable] = useState(1)
    const [pageIndex, setpageIndex] = useState(1)

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    useEffect(() => {
        postApi("Setting/setting-by-keys", {
            key: ["ENABLE_PRODUCT_STOCK"],
            userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
        }).then(({ data, status }) => {
            if (status == 200) {
                setEnableProductStock(data[0].Value == "1")
            }
        })
    }, [])


    const itemStockRegisterReportApi = () => {
        setIsLoading(true)
        postApi("Report/item-stock-report", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.ReportData)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    const ProductStockRegisterReportApi = () => {
        setIsLoading(true)
        postApi("Report/product-stock-report", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                data.ReportData.forEach(function (value) {
                    value.Validation = false
                });
                setTableData(data.ReportData)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // stock-register list API useEffect
    useEffect(() => {
        if (parseInt(stoctTable) == 1) {
            itemStockRegisterReportApi()
        } else {
            ProductStockRegisterReportApi()
        }
    }, [
        rowsPerPage,
        searchText,
        displayStart,
        stoctTable
    ])

    const productQtySave = () => {
        if (changeQtys.length > 0) {
            setIsLoading(true)
            postApi("Product/update-product-stock", changeQtys).then(({ data, status }) => {
                if (status === 200) {
                    ProductStockRegisterReportApi()
                    setIsLoading(false)
                    changeQtys = []
                } else {
                    setIsLoading(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        } else {
            toast.error("Please update product stock", {
                style: {
                    fontSize: "15px",
                },
            })
        }
    }

    const handleUpdateProductStock = (Id, Qty, NewQty) => {
        if (parseInt(NewQty) == 0 || NewQty == "") {
            toast.error(`You cannot add less than 1 qty`, {
                style: {
                    fontSize: "15px",
                },
            })
        }
        else if (parseInt(Qty) !== parseInt(NewQty)) {
            if (parseInt(Qty) > parseInt(NewQty)) {
                toast.error(`You cannot add less than ${Qty} qty`, {
                    style: {
                        fontSize: "15px",
                    },
                })
            } else {
                const index = changeQtys.findIndex(item => item.ProductId === Id);
                if (index !== -1) {
                    changeQtys[index].Qty = parseInt(NewQty);
                } else {
                    changeQtys.push({
                        ProductId: Id,
                        Qty: parseInt(NewQty),
                    });
                }

            }
            // tableData.forEach(function (value) {
            //     if (value.Id == Id) {
            //         // value.Qty = NewQty
            //     }
            // });

        }
    };

    const validationMSG = (pageIndex) => {
        if (changeQtys.length > 0 && parseInt(stoctTable) == 2) {
            DeleteModel.confirmDeleteInquiry({
                title: "",
                // title: "Are you sure? ",
                text: "Please save your changes, or they will be lost",
            }).then(response => {
                if (response == true) {
                    setpageIndex(pageIndex)
                    changeQtys = []
                }
            })
        } else {
            setpageIndex(pageIndex)
        }
    }

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    // Stock register table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        MRP
                    </div>
                ),
                accessor: "MRP",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.MRP || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Item Code",
                accessor: "ItemCode",
                width: "7%",
            },
            {
                Header: "Barcode No.",
                accessor: "BarcodeNo",
                width: "7%",
            },
            {
                Header: "Qty",
                accessor: "Qty",
                width: "5%",
            },

        ],
        [tableData]
    )

    const columns2 = useMemo(
        () => [
            {
                Header: "Code",
                accessor: "Code",
                width: "8%",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Category",
                accessor: "CategoryName",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.CategoryName ? row.original.CategoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Color",
                accessor: "ColorName",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.ColorName ? row.original.ColorName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Size",
                accessor: "SizeName",
                width: "5%",
                Cell: ({ row }) => (
                    <span>{row.original.SizeName ? row.original.SizeName : "N/A"}</span>
                ),
            },
            {
                Header: "Qty",
                accessor: "Qty",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <>
                            <div className="table-display">
                                <Input
                                    className="form-control-sm table-cell"
                                    placeholder="0"
                                    type="text"
                                    onFocus={(e) => { e.target.select() }}
                                    defaultValue={row.original.Qty}
                                    onKeyPress={handleKeyPress}
                                    onChange={e =>
                                        handleUpdateProductStock(row.original.Id, row.original.Qty, e.target.value)
                                    }
                                // invalid={parseInt(productId) == row.original.Id && isNewQtyValidation}
                                />
                            </div>
                        </>
                    )
                },
            },
        ],
        [tableData]
    )

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <StockRegisterTable
                            columns={parseInt(stoctTable) == 1 ? columns : columns2}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={20}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            enableProductStock={enableProductStock}
                            stoctTable={stoctTable}
                            setStockTable={setStockTable}
                            productQtySave={productQtySave}
                            changeQtys={changeQtys}
                            validationMSG={validationMSG}
                            pageIndex={pageIndex}
                            setpageIndex={setpageIndex}
                            className="custom-header-css"
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
StockeRegisterReport.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default StockeRegisterReport